import { takeLeading, put, select } from 'redux-saga/effects';
import { fetchHeaderPickup } from './actions';

function* headerPickupSaga() {
  const ttl = 60 * 60 * 1000;
  const timestamp = yield select((state) => state.headerPickup.lastFetched);
  const isFetched = yield select((state) => state.headerPickup.fetched);
  const isFetching = yield select((state) => state.headerPickup.fetching);

  const currentTime = Date.now();
  const shouldFetch = !isFetched || (currentTime - timestamp > ttl);

  if (shouldFetch && !isFetching) {
    yield put(fetchHeaderPickup());
  }
}

const headerPickupSagas = [
  takeLeading('HEADERPICKUP_REQUEST', headerPickupSaga),
];

export default headerPickupSagas;
