import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';

const initialState = {
  data: {
    mostUsedApps: [],
    mostUsedProfiles: [],
    mostUsedLinks: [],
  },
  fetching: false,
  fetched: false,
  error: null,
};

const headerPickup = (state = initialState, action) => {
  switch (action.type) {
    case 'HEADERPICKUP_FETCH':
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case 'HEADERPICKUP_FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload.data,
        timestamp: +new Date(),
        error: null,
      };

    case 'HEADERPICKUP_FETCH_ERROR':
      return {
        ...state,
        fetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
const persistConfig = {
  key: 'headerPickup',
  storage: storageSession,
  whitelist: ['data', 'fetched', 'timestamp'],
};

export default persistReducer(persistConfig, headerPickup);
